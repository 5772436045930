import { NgFor } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, inject, runInInjectionContext, Directive, Input, Component, ChangeDetectionStrategy } from '@angular/core';
const _c0 = ["blocks", ""];
function ContentComponent_ng_container_0_ng_template_1_Template(rf, ctx) {}
function ContentComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ContentComponent_ng_container_0_ng_template_1_Template, 0, 0, "ng-template", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const block_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("simpleRenderContent", block_r1);
  }
}
const SIMPLE_CONTENT_CONFIG = new InjectionToken('simple-content-config');
const isValidTemplate = (key, templates) => {
  return key in templates;
};
class RenderContentDirective {
  constructor(vcr, injector) {
    this.vcr = vcr;
    this.injector = injector;
    this.templates = inject(SIMPLE_CONTENT_CONFIG);
  }
  ngOnInit() {
    this.generateComponent(this.templates, this.content);
  }
  generateComponent(templates, content) {
    if (!isValidTemplate(content.type, templates)) {
      console.warn(`Widget '${content.type}' is not a known element.`);
      return;
    }
    templates[content.type]().then(config => {
      const renderedComponent = this.vcr.createComponent(config.props.component);
      renderedComponent.instance.data = runInInjectionContext(this.injector, () => config.props.mapResponse(content));
      renderedComponent.location.nativeElement.classList.add(`simple-widget`, `simple-widget-${content.type}`);
      renderedComponent.changeDetectorRef.markForCheck();
    });
  }
  static {
    this.ɵfac = function RenderContentDirective_Factory(t) {
      return new (t || RenderContentDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: RenderContentDirective,
      selectors: [["", "simpleRenderContent", ""]],
      inputs: {
        content: [i0.ɵɵInputFlags.None, "simpleRenderContent", "content"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RenderContentDirective, [{
    type: Directive,
    args: [{
      selector: '[simpleRenderContent]',
      standalone: true
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }, {
    type: i0.Injector
  }], {
    content: [{
      type: Input,
      args: ['simpleRenderContent']
    }]
  });
})();
class ContentComponent {
  static {
    this.ɵfac = function ContentComponent_Factory(t) {
      return new (t || ContentComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ContentComponent,
      selectors: [["simple-content", "blocks", ""]],
      inputs: {
        blocks: "blocks"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      attrs: _c0,
      decls: 1,
      vars: 1,
      consts: [[4, "ngFor", "ngForOf"], [3, "simpleRenderContent"]],
      template: function ContentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, ContentComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", ctx.blocks);
        }
      },
      dependencies: [NgFor, RenderContentDirective],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentComponent, [{
    type: Component,
    args: [{
      selector: 'simple-content[blocks]',
      standalone: true,
      imports: [NgFor, RenderContentDirective],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container *ngFor=\"let block of blocks;\">\n    <ng-template [simpleRenderContent]=\"block\" />\n</ng-container>",
      styles: [":host{display:block}\n"]
    }]
  }], null, {
    blocks: [{
      type: Input
    }]
  });
})();
class Widget {
  static {
    this.ɵfac = function Widget_Factory(t) {
      return new (t || Widget)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: Widget,
      inputs: {
        data: "data"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Widget, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], null, {
    data: [{
      type: Input
    }]
  });
})();
class WidgetConfig {
  constructor(props) {
    this.props = props;
  }
}
const DEFAULT_WIDGETS_CONFIG = {
  header: () => import('./trendency-simple-header.widget.config-EJxGAGOk.mjs').then(c => c.widgetConfig),
  list: () => import('./trendency-simple-list.widget.config-C0sg_Gkl.mjs').then(c => c.widgetConfig),
  quote: () => import('./trendency-simple-quote.widget.config-CQurW0aO.mjs').then(c => c.widgetConfig),
  table: () => import('./trendency-simple-table.widget.config-JIAZ8IQD.mjs').then(c => c.widgetConfig),
  paragraph: () => import('./trendency-simple-paragraph.widget.config-CYc2FUrP.mjs').then(c => c.widgetConfig),
  embed: () => import('./trendency-simple-embed.widget.config-BnXQhRHP.mjs').then(c => c.widgetConfig)
};
const provideSimpleConfig = config => ({
  provide: SIMPLE_CONTENT_CONFIG,
  useValue: {
    ...DEFAULT_WIDGETS_CONFIG,
    ...config
  }
});

/**
 * Generated bundle index. Do not edit.
 */

export { ContentComponent, Widget, WidgetConfig, provideSimpleConfig };
